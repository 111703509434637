@import 'colors';
@import 'theme';
@import 'datepicker';
@import url('https://fonts.googleapis.com/css?family=Titillium+Web:300,400,400i,700,900');
html, body {
	height: auto;
	width: 100%;
	font-family: 'Titillium Web', sans-serif;
}

.content {
	margin-top: 70px;
}

.space-top{margin-top:20px;}
.space-bottom{margin-bottom:20px;}

#checkbox-disponibilidade {
	input[type=checkbox]{
		display:none;
	}
	label{
		cursor: pointer;
		margin-right: 10px;
		// color: rgba(255, 0, 0, 0.453)!important;	
		text-decoration:line-through!important;
		&.checked{
			color: #333!important;
			font-weight: bold!important;
			text-decoration:none!important;
		}
	}
}


#linha0 {
	width: 0%;
	border-bottom: 1px solid #000000; }
	
	#linha1 {
		width: 100%;
		border-bottom: 1px solid #000000; }
		
		#linha3 {
			margin: 5px 40px 0px 70px;
			width: 80%;
			border-bottom: 2px solid #a59191c5; }
			
			#login {
				background-color: #262626;
				width: 100%;
				height: 100vh;
			}
			#marginPrint {
				margin: 5px 70px 0px 70px;
			}
			#login-navbar {
				min-height: 90px;
				width: 100%;
			}
			
			.panel.panel-default{
				padding: 20px;
			}
			
			.panel-print {
				padding: 10px;
				background-color: #FFFCE0;
			}
			.panel-extrato-position {
				padding: 0px !important;
				border: 1px solid #50514F;
			}
			.panel-extrato {
				text-align: center;
				width: 100%;
				padding: 20px 10px;
				background-color: #50514F;
				h3 {
					color: #FFF;
				}
				p {
					font-size: 16px;
					color: #FFF;
				}
			}
			.panel-extrato-body {
				background-color: #FFF;
				padding: 10px;
				.btn-outline-info {
					width: 100%;
					height: 40px;
					border: 1px solid #50514F;
					color: #50514F;
					background-color: transparent;
					text-transform: uppercase;
					font-size: 16px;
					border-radius: 3px;
					cursor: pointer;
					transition-duration: 0.3s;
					&:hover {
						background-color: #50514F;
						color: #FFF;
						transition-duration: 0.3s;
					}
				}
			}
			
			
			h1, h2, h3, h4, h5, h6 {
				font-family: 'Titillium Web', sans-serif;
				margin: 0 !important;
			}
			
			
			label {
				font-family: 'Titillium Web', sans-serif;
				color: #333!important;
				font-size: 14px!important;
				font-weight: 400!important;
			}
			
			.label-welcome {
				font-family: 'Titillium Web', sans-serif;
				color: #fff;
				font-size: 25px;
				font-style: italic;
				margin-top: 20px;
				line-height: 1.2;
			}
			
			.div-login {
				position: absolute;
				left: 50%;
				top: 50%;
				z-index: 1000;
				transform: translate(-50%, -50%);
				width: 30%;
				height: auto;
				-webkit-box-shadow: 1px 1px 5px 2px rgba(0,0,0,0.2);
				box-shadow: 1px 1px 5px 2px rgba(0,0,0,0.2);
				padding: 20px;
				background-color: #fff;
				border-radius: 3px;
			}
			
			@media (max-width: 768px) {
				.div-login {
					width: 80%;
				}
			}
			
			.login-multsaude {
				position: absolute;
				right: 20px;
				bottom: 20px;
				width: 100px;
				height: auto;
			}
			
			.btn-entrar {
				background: $MAIN_COLOR;
				border-radius: 3px;
				width: 100%;
				color: #FFF;
				font-weight: bold;
				outline: none!important;
			}
			.btn-entrar:hover {
				background: $DARK_MAIN_COLOR;
				border-radius: 3px;
				width: 100%;
				color: #FFF!important;
				font-weight: bold;
			}
			.btn-entrar:focus {
				background: $DARK_MAIN_COLOR;
				border-radius: 3px;
				width: 100%;
				color: #FFF!important;
				font-weight: bold;
			}
			
			.div-login label{
				color: #222;
				font-weight: 400;
			}
			
			a[href="/admin/recuperar"] {
				color: #222;
			}
			a[href="/admin/recuperar"]:hover {
				color: #222;
				text-decoration: underline;
			}
			a[href="/admin/recuperar"]:focus {
				color: #222;
			}
			
			#login-titulo {
				width: 100%;
				height: auto;
				position: absolute;
				top: -70px;
				color: #fff;
				left: 0;
			}
			
			.user > .info > a {
				text-transform: uppercase;
				font-weight: bold;
			}
			.user > .info > a:hover {
				text-decoration: none;
			}
			.user > .info > a:focus {
				text-decoration: none;
			}
			
			.border-red {
				border: 1px solid red !important;
			}
			
			.input-group-custom {
				display: flex;
				width: 100%;
			}
			
			.input-col-1 {
				width: 20%;
			}
			
			.input-col-2 {
				width: 80%;
			}
			
			.input-col-1 > input {
				border-top-right-radius: 0px;
				border-bottom-right-radius: 0px;
			}
			
			.input-col-2 > input {
				border-top-left-radius: 0px;
				border-bottom-left-radius: 0px;
				margin-left: -2px;
			}
			
			.nav.navbar-nav > li > h4 {
				line-height: 60px;
				color: #FFF!important;
			}
			
			.navbar-default {
				background-color: $MAIN_COLOR!important;
				border-color: transparent;
			}
			
			.sidebar .logo, body > .navbar-collapse .logo {
				padding: 10px 15px;
				border-bottom: 1px solid rgba(255, 255, 255, 0.2);
				border-right: 1px solid rgba(255, 255, 255, 0.2);
				background-color: $MAIN_COLOR!important;
			}
			
			.btn-link {
				i.glyphicon {
					top: 3px!important;
				}
			}
			
			.alert {
				padding: 15px;
				margin-bottom: 20px;
				border: 1px solid transparent;
				border-radius: 4px;
				position: fixed!important;
				top: 7px!important;
				z-index: 99999!important;
				width: 60%!important;
			}
			
			.actions > a {
				font-weight: 500;
				display: inline-block;
				color: #337ab7;
				margin-bottom: 5px;
			}
			
			.actions > a#left-pipe:before {
				content: "";
				display: inline-block;
				height: 1em;
				width: 1px;
				vertical-align: -2px;
				background: #337ab7;
				margin-left: 5px;
				margin-right: 10px;
			}
			
			.itens-btn {
				border: 1px solid;
				padding: 5px 15px;
				border-radius: 5px;
				&:hover{
					text-decoration: none;
				}
			}
			
			span.badge-custom {
				text-align: center;
				position: absolute;
				right: 15px;
				top: 14px;
				background-color: #c9302c;
				padding: 0;
				border-radius: 3px;
				min-width: 24px;
				min-height: 24px;
			}
			
			span.badge-custom > label {
				margin: 2px;
				color: #FFF!important;
			}
			
			span.badge-custom-inner {
				text-align: center;
				position: absolute;
				right: 15px;
				top: 8px;
				background-color: #c9302c;
				padding: 0;
				border-radius: 3px;
				min-width: 24px;
				min-height: 24px;
			}
			
			span.badge-custom-inner > label {
				margin: 2px;
				color: #FFF!important;
			}
			
			input[type=file] {
				position: absolute;
				clip: rect(0px 0px 0px 0px);
			}
			
			.input-group-file {
				border: 1px solid #337ab7;
				background-color: #337ab7;
				color: #FFF;
				padding: 0 15px;
				border-top-right-radius: 3px;
				border-bottom-right-radius: 3px;
				label {
					margin: 0;
					color: #FFF!important;
					font-size: 12px;
					font-weight: 400;
					text-transform: uppercase;
				}
			}
			.removerPlano {
				border: 1px solid #c9302c!important;
				background-color: #c9302c!important;
				color: #FFF;
			}
			
			.input-group-file * {
				cursor: pointer;
			}
			
			
			@mixin aspect-ratio($width, $height) {
				position: relative;
				&:before{
					display: block;
					content: " ";
					width: 100%;
					padding-top: ($height / $width) * 100%;
				}
				
				> .cropArea {
					background: #FFF;
					overflow: hidden;
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					box-shadow: rgba(0,0,0,0.12) 0px 1px 1px 1px;
					i {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
					}
				}
			}
			
			.destaque-plano1 {
				@include aspect-ratio(8,5);  
			}
			
			.rodape-plano2 {
				@include aspect-ratio(8,1.6);  
			}
			
			.logo-plano3 {
				@include aspect-ratio(2,2);  
			}
			
			.cover-plano4 {
				@include aspect-ratio(8,3);  
			}
			
			
			.sk-fading-circle {
				width: 40px;
				height: 40px;
				position: absolute;
				left: calc(50% - 20px);
				top: calc(50% - 20px);
			}
			
			.sk-fading-circle .sk-circle {
				width: 100%;
				height: 100%;
				position: absolute;
				left: 0;
				top: 0;
			}
			
			.sk-fading-circle .sk-circle:before {
				content: '';
				display: block;
				margin: 0 auto;
				width: 15%;
				height: 15%;
				background-color: #333;
				border-radius: 100%;
				-webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
				animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
			}
			.sk-fading-circle .sk-circle2 {
				-webkit-transform: rotate(30deg);
				-ms-transform: rotate(30deg);
				transform: rotate(30deg);
			}
			.sk-fading-circle .sk-circle3 {
				-webkit-transform: rotate(60deg);
				-ms-transform: rotate(60deg);
				transform: rotate(60deg);
			}
			.sk-fading-circle .sk-circle4 {
				-webkit-transform: rotate(90deg);
				-ms-transform: rotate(90deg);
				transform: rotate(90deg);
			}
			.sk-fading-circle .sk-circle5 {
				-webkit-transform: rotate(120deg);
				-ms-transform: rotate(120deg);
				transform: rotate(120deg);
			}
			.sk-fading-circle .sk-circle6 {
				-webkit-transform: rotate(150deg);
				-ms-transform: rotate(150deg);
				transform: rotate(150deg);
			}
			.sk-fading-circle .sk-circle7 {
				-webkit-transform: rotate(180deg);
				-ms-transform: rotate(180deg);
				transform: rotate(180deg);
			}
			.sk-fading-circle .sk-circle8 {
				-webkit-transform: rotate(210deg);
				-ms-transform: rotate(210deg);
				transform: rotate(210deg);
			}
			.sk-fading-circle .sk-circle9 {
				-webkit-transform: rotate(240deg);
				-ms-transform: rotate(240deg);
				transform: rotate(240deg);
			}
			.sk-fading-circle .sk-circle10 {
				-webkit-transform: rotate(270deg);
				-ms-transform: rotate(270deg);
				transform: rotate(270deg);
			}
			.sk-fading-circle .sk-circle11 {
				-webkit-transform: rotate(300deg);
				-ms-transform: rotate(300deg);
				transform: rotate(300deg); 
			}
			.sk-fading-circle .sk-circle12 {
				-webkit-transform: rotate(330deg);
				-ms-transform: rotate(330deg);
				transform: rotate(330deg); 
			}
			.sk-fading-circle .sk-circle2:before {
				-webkit-animation-delay: -1.1s;
				animation-delay: -1.1s; 
			}
			.sk-fading-circle .sk-circle3:before {
				-webkit-animation-delay: -1s;
				animation-delay: -1s; 
			}
			.sk-fading-circle .sk-circle4:before {
				-webkit-animation-delay: -0.9s;
				animation-delay: -0.9s; 
			}
			.sk-fading-circle .sk-circle5:before {
				-webkit-animation-delay: -0.8s;
				animation-delay: -0.8s; 
			}
			.sk-fading-circle .sk-circle6:before {
				-webkit-animation-delay: -0.7s;
				animation-delay: -0.7s; 
			}
			.sk-fading-circle .sk-circle7:before {
				-webkit-animation-delay: -0.6s;
				animation-delay: -0.6s; 
			}
			.sk-fading-circle .sk-circle8:before {
				-webkit-animation-delay: -0.5s;
				animation-delay: -0.5s; 
			}
			.sk-fading-circle .sk-circle9:before {
				-webkit-animation-delay: -0.4s;
				animation-delay: -0.4s;
			}
			.sk-fading-circle .sk-circle10:before {
				-webkit-animation-delay: -0.3s;
				animation-delay: -0.3s;
			}
			.sk-fading-circle .sk-circle11:before {
				-webkit-animation-delay: -0.2s;
				animation-delay: -0.2s;
			}
			.sk-fading-circle .sk-circle12:before {
				-webkit-animation-delay: -0.1s;
				animation-delay: -0.1s;
			}
			
			@-webkit-keyframes sk-circleFadeDelay {
				0%, 39%, 100% { opacity: 0; }
				40% { opacity: 1; }
			}
			
			@keyframes sk-circleFadeDelay {
				0%, 39%, 100% { opacity: 0; }
				40% { opacity: 1; } 
			}
			
			.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
				color: #59744a!important;
				background-color: #ffffff!important;
				border: 1px solid #dddddd!important;
				border-bottom-color: transparent!important;
				cursor: pointer;
			}
			
			.nav-tabs > li > a {
				border: 1px solid #dddddd!important;
				border-bottom-color: transparent!important;
				cursor: pointer;
			}
			
			.nav-tabs > li > a, .nav-tabs > li > a:hover, .nav-tabs > li > a:focus {
				color: #6b6b6b!important;
				background-color: transparent;
				border: 1px solid #dddddd;
				border-bottom-color: transparent;
				cursor: pointer;
			}
			
			.label-danger {
				border: 1px solid #d9534f!important;
				background-color: transparent!important;
				padding: 5px;
				label {
					color: #d9534f!important;
					margin: 0!important;
				}
			}
			
			.label-default {
				border: 1px solid #333!important;
				background-color: transparent!important;
				padding: 5px;
				label {
					color: #333!important;
					margin: 0!important;
				}
			}
			
			.label-warning {
				border: 1px solid #f0ad4e!important;
				background-color: transparent!important;
				padding: 5px;
				label {
					color: #f0ad4e!important;
					margin: 0!important;
				}
			}
			
			.label-success {
				border: 1px solid $MAIN_COLOR!important;
				background-color: transparent!important;
				padding: 5px;
				label {
					color: $MAIN_COLOR!important;
					margin: 0!important;
				}
			}
			
			
			.navigation-link, .navigation-link:hover, .navigation-link:focus {
				color: #555555;
				/*text-decoration: none;*/
				cursor: pointer;
			}
			.navigation-link-active {
				text-decoration: underline;
				color: #555555;
				cursor: pointer;
			}
			.navigation-icon {
				margin-left: 5px;
				color:#282828;
			}
			
			.full-width{
				width: 100% !important;
			}
			
			.cobranca-details {
				h1 {
					font-size: 30px;
					color: #4C4D50;
					text-transform: capitalize;
				}
				label {
					color: #9298A0;
					letter-spacing: 1.05px;
				}
				span {
					color: #4D4D4D;
					font-weight: 300;
				}
				i {
					margin-right: 10px;
				}
				button {
					margin-bottom: 10px;
					width: 100%;
					font-size: 11px;
					font-weight: 700;
				}
				input {
					height: 35px;
				}
				textarea {
					-webkit-transition: height 0.3s ease-in-out;
					-moz-transition: height 0.3s ease-in-out;
					-ms-transition: height 0.3s ease-in-out;
					-o-transition: height 0.3s ease-in-out;
					transition: height 0.3s ease-in-out;
				}
				.btn-response {
					button {
						width: 40%;
					}
				}
			}
			
			.flex-div {
				display: flex;
				align-items: center;
			}
			
			@for $i from 1 through 100 {
				.flex-item--#{$i} {
					width: 0% + $i;
					margin-left: 10px;
					margin-right: 10px;
					position: relative;
				}	
			}
			
			.toggleOn{
				color: #4cd964;
			}
			
			.toggleOff{
				color: #555555;
				-moz-transform: scaleX(-1);    /* Gecko */
				-o-transform: scaleX(-1);      /* Opera */
				-webkit-transform: scaleX(-1); /* Webkit */
				transform: scaleX(-1);         /* Standard */
				filter: FlipH;                 /* IE 6/7/8 */
			}