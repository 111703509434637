$BLUE: 				#3060CA;
$MAIN_COLOR: 		#3AE865;
$BACKGROUND_COLOR: 	#EBEBE6;
$BLUE_COLOR:		#3060CA ;
$WHITE_COLOR:		#FFFFFF ;
$BLACK_COLOR:		#000000 ;
$DARK_MAIN_COLOR:	#1cc044 ;
$RED_COLOR:			#FF4B4B ;
$GRAY_COLOR:		#50514F ;
$DARK_COLOR:		#1A1A1A ;
$YELLOW_COLOR:		#ff9800 ;