/*NAV-DATA STYLE*/
.pill {
	height: 60px;
	height: 60px;
	margin-bottom: 1px;
	/*background: #e3eaf3;*/
  	color: #50514F;
	display:table-cell;
	vertical-align: middle;
	text-align: center;
	cursor: pointer;
}
.pill.active {background: #50514F; color: #FFFFFF;}
.pill:focus {background: #50514F; color: #FFFFFF;}
.pill:hover {background: #50514F; color: #FFFFFF;}
ul#monthScrool {
	margin: 0;
	height: 45px;
}
ul.unstyled {
	margin-left: 0;
	list-style: none;
}
ul#monthScroll li {
	display: block;
	float: left;
}
li.month a p {
	margin: 4px;
	text-align: center;
}
li.month a {
	font-size: 10px;
	line-height: 20px;
	text-transform: uppercase;
	color: #7090ab;
	text-decoration: none;
}
li.month a:hover > .pill-date{
	background: #C0D0E4;
}
li.page-prev-next a:hover > .pill-page{
	background: #C0D0E4;
}
li.pill-mes-atual a:hover > .div-mes-atual{
	background: #C0D0E4;
}
.pill-active > .pill-date{
	background: #29D615;
}
.pill-active > p{
	color: #29D615;
}
.span-date ul li{
	display: inline;
}
.ul-date{
	display: inline-block;
	padding-left: 0;
	margin: 5px 0;
}
.ul-date > li {
	display: inline;
}
.ul-date > li > a {
	position: relative;
	float: left;
	padding: 2px 2px;
	text-decoration: none;
	margin-left: -1px;
}
.table{
	width: 100% !important; 
}
/*-----------------------------------------------------*/