@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
  }
}
/*           Font Smoothing      */
body,
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6,
p,
.navbar,
.brand,
.btn-simple,
.alert,
a,
.td-name,
td,
button.close {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

h1 .subtitle {
  display: block;
  margin: 0 0 30px;
}

.text-muted {
  color: #9A9A9A;
}

.text-primary, .text-primary:hover {
  color: #1D62F0 !important;
}

.text-info, .text-info:hover {
  color: #1DC7EA !important;
}

.text-success, .text-success:hover {
  color: #87CB16 !important;
}

.text-warning, .text-warning:hover {
  color: #FF9500 !important;
}

.text-danger, .text-danger:hover {
  color: #FF4A55 !important;
}

/*     General overwrite     */
body,
.wrapper {
  min-height: 100vh;
  position: relative;
}

a {
  color: #005F34;
}
a:hover, a:focus {
  color: #005F34;
  text-decoration: none;
}

a:focus, a:active,
button::-moz-focus-inner,
input::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  outline: 0;
}

.ui-slider-handle:focus,
.navbar-toggle,
input:focus {
  outline: 0 !important;
}

/*           Animations              */
.form-control,
.input-group-addon,
.tagsinput,
.navbar,
.navbar .alert {
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear;
}

.sidebar .nav a,
.table > tbody > tr .td-actions .btn {
  -webkit-transition: all 150ms ease-in;
  -moz-transition: all 150ms ease-in;
  -o-transition: all 150ms ease-in;
  -ms-transition: all 150ms ease-in;
  transition: all 150ms ease-in;
}

.btn {
  -webkit-transition: all 100ms ease-in;
  -moz-transition: all 100ms ease-in;
  -o-transition: all 100ms ease-in;
  -ms-transition: all 100ms ease-in;
  transition: all 100ms ease-in;
}


.margin-top {
  margin-top: 50px;
}

.wrapper {
  position: relative;
  top: 0;
  height: 100vh;
}

.perfect-scrollbar-off .sidebar .sidebar-wrapper, .perfect-scrollbar-off .main-panel {
    overflow: auto;
}

.sidebar .logo, .bootstrap-navbar .logo {
    padding: 10px 15px;
    position: relative;
    z-index: 4;
}

.sidebar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 140px;
  display: block;
  z-index: 4;
  color: #fff;
  font-weight: 200;
  background-size: cover;
  background-position: center center;
  background: #59744A;
}
.sidebar .sidebar-wrapper {
  position: relative;
  z-index: 4;
  width: 100%;
  height: calc(100vh - 65px);
}
.sidebar .sidebar-wrapper > .nav {
  margin-top: 20px;
}
.sidebar .sidebar-wrapper > .user ~ .nav {
  margin-top: 0;
}
.sidebar .sidebar-wrapper .dropdown .dropdown-backdrop {
  display: none !important;
}
.sidebar .sidebar-background {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center center;
}
.sidebar .logo {
  padding: 10px 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.sidebar .logo p {
  float: left;
  font-size: 20px;
  margin: 10px 10px;
  color: #FFFFFF;
  line-height: 20px;
  font-family: 'Open Sans', sans-serif;
}
.sidebar .logo .simple-text {
  text-transform: uppercase;
  padding: 5px 0px;
  display: block;
  font-size: 18px;
  color: #FFFFFF;
  text-align: center;
  font-weight: 400;
  line-height: 30px;
}
.sidebar .logo-tim {
  border-radius: 50%;
  border: 1px solid #333;
  display: block;
  height: 61px;
  width: 61px;
  float: left;
  overflow: hidden;
}
.sidebar .logo-tim img {
  width: 60px;
  height: 60px;
}

.sidebar .user,
.bootstrap-navbar .user {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 20px;
  margin-top: 20px;
}
.sidebar .user .photo,
.bootstrap-navbar .user .photo {
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 50%;
  border: 4px solid #7b7b7b;
  background-color: transparent;
  margin: 0 auto;
}
.sidebar .user .photo img,
.bootstrap-navbar .user .photo img {
  width: 72px;
  height: auto;
}
.sidebar .user a,
.bootstrap-navbar .user a {
  text-align: center;
  color: #FFFFFF;
  padding: 8px 14px;
  text-align: center;
  display: block;

}

.nav>li>a:focus, .nav>li>a:hover {
    text-decoration: none;
    background-color: rgba(255, 255, 255, 0.23) !important;
}

.sidebar .nav {
  margin-top: 0px;
}
.sidebar .nav li > a {
  color: #FFFFFF;
  // margin: 5px 15px;
  opacity: .86;
  // border-radius: 4px;
}
.sidebar .nav li:hover > a {
  background: rgba(255, 255, 255, 0.13);
  opacity: 1;
}
.sidebar .nav li.active > a {
  color: #FFFFFF;
  opacity: 1;
  background: rgba(255, 255, 255, 0.23)!important;
}
.sidebar .nav p {
  margin: 0;
  // line-height: 30px;
  font-size: 9px;
  font-weight: 500;
  text-transform: uppercase;
}
.sidebar .nav i {
  font-size: 28px;
  // float: left;
  // margin-right: 15px;
  line-height: 40px;
  width: 30px;
  text-align: center;
}

.sidebar .logo,
body > .navbar-collapse .logo {
  padding: 10px 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.sidebar .logo p,
body > .navbar-collapse .logo p {
  float: left;
  font-size: 20px;
  margin: 10px 10px;
  color: #FFFFFF;
  line-height: 20px;
  font-family: 'Open Sans', sans-serif;
}
.sidebar .logo .simple-text,
body > .navbar-collapse .logo .simple-text {
  text-transform: uppercase;
  padding: 5px 0px;
  display: block;
  font-size: 18px;
  color: #FFFFFF;
  text-align: center;
  font-weight: 400;
  line-height: 30px;
}
.sidebar .logo-tim,
body > .navbar-collapse .logo-tim {
  border-radius: 50%;
  border: 1px solid #333;
  display: block;
  height: 61px;
  width: 61px;
  float: left;
  overflow: hidden;
}
.sidebar .logo-tim img,
body > .navbar-collapse .logo-tim img {
  width: 60px;
  height: 60px;
}
.sidebar:after, .sidebar:before,
body > .navbar-collapse:after,
body > .navbar-collapse:before {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}
.sidebar:before,
body > .navbar-collapse:before {
  opacity: .33;
  background: #000000;
}
.sidebar:after,
body > .navbar-collapse:after {
  background: transparent;
}
.sidebar[data-image]:after, .sidebar.has-image:after,
body > .navbar-collapse[data-image]:after,
body > .navbar-collapse.has-image:after {
  opacity: .77;
}
.sidebar[data-color="blue"]:after,
body > .navbar-collapse[data-color="blue"]:after {
  background: #1F77D0;
  background: -moz-linear-gradient(top, #1F77D0 0%, #533ce1 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #1F77D0), color-stop(100%, #533ce1));
  background: -webkit-linear-gradient(top, #1F77D0 0%, #533ce1 100%);
  background: -o-linear-gradient(top, #1F77D0 0%, #533ce1 100%);
  background: -ms-linear-gradient(top, #1F77D0 0%, #533ce1 100%);
  background: linear-gradient(to bottom, #1F77D0 0%, #533ce1 100%);
  background-size: 150% 150%;
}
.sidebar[data-color="azure"]:after,
body > .navbar-collapse[data-color="azure"]:after {
  background: #1DC7EA;
  background: -moz-linear-gradient(top, #1DC7EA 0%, #4091ff 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #1DC7EA), color-stop(100%, #4091ff));
  background: -webkit-linear-gradient(top, #1DC7EA 0%, #4091ff 100%);
  background: -o-linear-gradient(top, #1DC7EA 0%, #4091ff 100%);
  background: -ms-linear-gradient(top, #1DC7EA 0%, #4091ff 100%);
  background: linear-gradient(to bottom, #1DC7EA 0%, #4091ff 100%);
  background-size: 150% 150%;
}
.sidebar[data-color="green"]:after,
body > .navbar-collapse[data-color="green"]:after {
  /*background-image: linear-gradient(-180deg, #59744A 0%, #C5D254 100%);*/
  opacity: 1;
  background: $MAIN_COLOR;
  background: -moz-linear-gradient(top, $MAIN_COLOR 0%, $DARK_MAIN_COLOR 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $MAIN_COLOR), color-stop(100%, $DARK_MAIN_COLOR));
  background: -webkit-linear-gradient(top, $MAIN_COLOR 0%, $DARK_MAIN_COLOR 100%);
  background: -o-linear-gradient(top, $MAIN_COLOR 0%, $DARK_MAIN_COLOR 100%);
  background: -ms-linear-gradient(top, $MAIN_COLOR 0%, $DARK_MAIN_COLOR 100%);
  background: linear-gradient(to bottom, $MAIN_COLOR 0%, $DARK_MAIN_COLOR 100%);
  background-size: 150% 150%;
  height: 100%;

}
.sidebar[data-color="black"]:after,
body > .navbar-collapse[data-color="black"]:after {
  background: $GRAY_COLOR;
  background-size: 150% 150%;
}
.sidebar[data-color="red"]:after,
body > .navbar-collapse[data-color="red"]:after {
  background: #FB404B;
  background: -moz-linear-gradient(top, #FB404B 0%, #bb0502 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #FB404B), color-stop(100%, #bb0502));
  background: -webkit-linear-gradient(top, #FB404B 0%, #bb0502 100%);
  background: -o-linear-gradient(top, #FB404B 0%, #bb0502 100%);
  background: -ms-linear-gradient(top, #FB404B 0%, #bb0502 100%);
  background: linear-gradient(to bottom, #FB404B 0%, #bb0502 100%);
  background-size: 150% 150%;
}
.sidebar[data-color="purple"]:after,
body > .navbar-collapse[data-color="purple"]:after {
  background: #9368E9;
  background: -moz-linear-gradient(top, #9368E9 0%, #943bea 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #9368E9), color-stop(100%, #943bea));
  background: -webkit-linear-gradient(top, #9368E9 0%, #943bea 100%);
  background: -o-linear-gradient(top, #9368E9 0%, #943bea 100%);
  background: -ms-linear-gradient(top, #9368E9 0%, #943bea 100%);
  background: linear-gradient(to bottom, #9368E9 0%, #943bea 100%);
  background-size: 150% 150%;
}

.main-panel {
  background: rgba(203, 203, 210, 0.15);
  position: relative;
  z-index: 2;
  float: right;
  width: calc(100% - 140px);
  min-height: 100%;
}
.main-panel > .content {
  padding: 0px 15px;
  min-height: calc(100% - 123px);
}
.main-panel > .footer {
  border-top: 1px solid #e7e7e7;
}
.main-panel .navbar {
  margin-bottom: 0;
}

.sidebar,
.main-panel {
  max-height: 100%;
  height: 100%;
  -webkit-transition-property: top,bottom;
  transition-property: top,bottom;
  -webkit-transition-duration: .2s,.2s;
  transition-duration: .2s,.2s;
  -webkit-transition-timing-function: linear,linear;
  transition-timing-function: linear,linear;
  -webkit-overflow-scrolling: touch;
}

.perfect-scrollbar-on .sidebar .sidebar-wrapper, .sidebar .sidebar-wrapper, .perfect-scrollbar-on
.main-panel,
.main-panel {
  overflow: hidden;
}

.perfect-scrollbar-off .sidebar .sidebar-wrapper,
.perfect-scrollbar-off .main-panel {
  overflow: auto;
}


.form-control::-moz-placeholder {
  color: #DDDDDD;
  opacity: 1;
  filter: alpha(opacity=100);
}

.form-control:-moz-placeholder {
  color: #DDDDDD;
  opacity: 1;
  filter: alpha(opacity=100);
}

.form-control::-webkit-input-placeholder {
  color: #DDDDDD;
  opacity: 1;
  filter: alpha(opacity=100);
}

.form-control:-ms-input-placeholder {
  color: #DDDDDD;
  opacity: 1;
  filter: alpha(opacity=100);
}

.form-control {
  background-color: #FFFFFF;
  border: 1px solid #E3E3E3;
  border-radius: 4px;
  color: #565656;
  padding: 8px 12px;
  height: 40px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-control:focus {
  background-color: #FFFFFF;
  border: 1px solid #AAAAAA;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0 !important;
  color: #333333;
}
.has-success .form-control, .has-error .form-control, .has-success .form-control:focus, .has-error .form-control:focus {
  border-color: #E3E3E3;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-success .form-control {
  color: #87CB16;
}
.has-success .form-control:focus {
  border-color: #87CB16;
}
.has-error .form-control {
  color: #FF4A55;
}
.has-error .form-control:focus {
  border-color: #FF4A55;
}
.form-control + .form-control-feedback {
  border-radius: 6px;
  font-size: 14px;
  margin-top: -7px;
  position: absolute;
  right: 10px;
  top: 50%;
  vertical-align: middle;
}
.open .form-control {
  border-radius: 4px 4px 0 0;
}

.input-lg {
  height: 55px;
  padding: 14px 30px;
}

.has-error .form-control-feedback {
  color: #FF4A55;
}

.has-success .form-control-feedback {
  color: #87CB16;
}

.input-group-addon {
  background-color: #FFFFFF;
  border: 1px solid #E3E3E3;
  border-radius: 4px;
}
.has-success .input-group-addon, .has-error .input-group-addon {
  background-color: #FFFFFF;
  border: 1px solid #E3E3E3;
}
.has-error .form-control:focus + .input-group-addon {
  border-color: #FF4A55;
  color: #FF4A55;
}
.has-success .form-control:focus + .input-group-addon {
  border-color: #87CB16;
  color: #87CB16;
}
.form-control:focus + .input-group-addon, .form-control:focus ~ .input-group-addon {
  background-color: #FFFFFF;
  border-color: #9A9A9A;
}

.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
  border-right: 0 none;
}

.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child) {
  /*border-left: 0 none;*/
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
  background-color: #F5F5F5;
  color: #888888;
  cursor: not-allowed;
}

.input-group-btn .btn {
  border-width: 1px;
  padding: 9px 16px;
}

.input-group-btn .btn-default:not(.btn-fill) {
  border-color: #DDDDDD;
}

.input-group-btn:last-child > .btn {
  margin-left: 0;
}

.input-group-focus .input-group-addon {
  border-color: #9A9A9A;
}

.alert {
  border: 0;
  border-radius: 0;
  color: #FFFFFF;
  padding: 10px 30px;
  font-size: 14px;
}
.container .alert {
  border-radius: 4px;
}
.navbar .alert {
  border-radius: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 85px;
  width: 100%;
  z-index: 3;
}
.navbar:not(.navbar-transparent) .alert {
  top: 70px;
}
.alert span[data-notify="icon"] {
  font-size: 30px;
  display: block;
  left: 15px;
  position: absolute;
  top: 50%;
  margin-top: -15px;
}
.alert button.close {
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -13px;
  z-index: 1033;
  background-color: #FFFFFF;
  display: block;
  border-radius: 50%;
  opacity: .4;
  line-height: 11px;
  width: 25px;
  height: 25px;
  outline: 0 !important;
  text-align: center;
  padding: 3px;
  font-weight: 300;
  color: #000;
}
.alert button.close:hover {
  opacity: .55;
}
.alert .close ~ span {
  display: block;
  max-width: 89%;
}
.alert[data-notify="container"] {
  padding: 10px 10px 10px 20px;
  border-radius: 4px;
}
.alert.alert-with-icon {
  padding-left: 65px;
}

.alert-info {
  background-color: #63d8f1;
}

.alert-success {
  background-color: #a1e82c;
}

.alert-warning {
  background-color: #ffbc67;
}

.alert-danger {
  background-color: #fc727a;
}

.table .radio,
.table .checkbox {
  position: relative;
  height: 20px;
  display: block;
  width: 20px;
  padding: 0px 0px;
  margin: 0px 5px;
  text-align: center;
}
.table .radio .icons,
.table .checkbox .icons {
  left: 5px;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 12px 8px;
  vertical-align: middle;
}
.table > thead > tr > th {
  border-bottom-width: 1px;
  font-size: 12px;
  text-transform: uppercase;
  color: #9A9A9A;
  font-weight: 400;
  padding-bottom: 5px;
}
.table .td-actions .btn {
  opacity: 0.36;
  filter: alpha(opacity=36);
}
.table .td-actions .btn.btn-xs {
  padding-left: 3px;
  padding-right: 3px;
}
.table .td-actions {
  min-width: 90px;
}
.table > tbody > tr {
  position: relative;
}
.table > tbody > tr:hover .td-actions .btn {
  opacity: 1;
  filter: alpha(opacity=100);
}



input[type="radio"], input[type="checkbox"] {
    margin: 5px 0 0!important;
    margin-top: 1px \9;
    line-height: normal;
}

.radio input[type="radio"], .radio-inline input[type="radio"], .checkbox input[type="checkbox"], .checkbox-inline input[type="checkbox"] {
    position: absolute;
    margin-left: -20px!important;
    margin-top: 4px \9;
}

/* ============================================================
 * bootstrapSwitch v1.3 by Larentis Mattia @spiritualGuru
 * http://www.larentis.eu/switch/
 * ============================================================
 * Licensed under the Apache License, Version 2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 * ============================================================ */
.has-switch {
  border-radius: 30px;
  cursor: pointer;
  display: inline-block;
  line-height: 1.72222;
  overflow: hidden;
  position: relative;
  text-align: left;
  width: 60px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  /*   this code is for fixing safari bug with hidden overflow for border-radius   */
  -webkit-mask: url("../img/mask.png") 0 0 no-repeat;
  -webkit-mask-size: 60px 24px;
  mask: url("../img/mask.png") 0 0 no-repeat;
}

.has-switch.deactivate {
  opacity: 0.5;
  filter: alpha(opacity=50);
  cursor: default !important;
}

.has-switch.deactivate label,
.has-switch.deactivate span {
  cursor: default !important;
}

.has-switch > div {
  position: relative;
  top: 0;
  width: 140px;
}

.has-switch > div.switch-animate {
  -webkit-transition: left 0.25s ease-out;
  transition: left 0.25s ease-out;
}

.has-switch > div.switch-off {
  left: -35px;
}

.has-switch > div.switch-on {
  left: 0;
}

.has-switch > div label {
  background-color: #FFFFFF;
  background: white;
  background: -moz-linear-gradient(top, white 0%, #f1f1f2 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, white), color-stop(100%, #f1f1f2));
  background: -webkit-linear-gradient(top, white 0%, #f1f1f2 100%);
  background: -o-linear-gradient(top, white 0%, #f1f1f2 100%);
  background: -ms-linear-gradient(top, white 0%, #f1f1f2 100%);
  background: linear-gradient(to bottom, white 0%, #f1f1f2 100%);
  background-size: 150% 150%;
  box-shadow: 0 1px 1px #FFFFFF inset, 0 1px 1px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.has-switch input[type=checkbox] {
  display: none;
}

.has-switch span {
  /*     box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2) inset; */
  cursor: pointer;
  float: left;
  font-size: 11px;
  font-weight: 400;
  height: 24px;
  line-height: 15px;
  margin: 0;
  padding-bottom: 6px;
  padding-top: 5px;
  position: relative;
  text-align: center;
  text-indent: -10px;
  width: 50%;
  z-index: 1;
  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
}

.has-switch span.switch-left {
  background-color: #1DC7EA;
  border-left: 1px solid transparent;
  border-radius: 30px 0 0 30px;
  color: #FFFFFF;
}

.has-switch .switch-off span.switch-left {
  background-color: #DDDDDD;
}

.has-switch span.switch-right {
  border-radius: 0 30px 30px 0;
  background-color: #1DC7EA;
  color: #ffffff;
  text-indent: 1px;
}

.has-switch .switch-off span.switch-right {
  background-color: #DDDDDD;
}

.has-switch label {
  border-radius: 12px;
  float: left;
  height: 22px;
  margin: 1px -13px;
  padding: 0;
  position: relative;
  transition: all 0.25s ease-out 0s;
  vertical-align: middle;
  width: 22px;
  z-index: 100;
  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
}

.has-switch .switch-on .fa-check:before {
  margin-left: 10px;
}

.has-switch:hover .switch-on label {
  margin: 1px -17px;
  width: 26px;
}

.has-switch:hover .switch-off label {
  margin: 1px -13px;
  width: 26px;
}

.nav > li > a:hover,
.nav > li > a:focus {
  background-color: transparent;
}

.navbar {
  border: 0!important;
  font-size: 16px;
  border-radius: 0!important;
  min-height: 71px!important;
}
.navbar .navbar-brand {
  font-weight: 400;
  margin: 5px 0px;
  padding: 20px 15px;
  font-size: 20px;
}
.navbar .navbar-nav > li > a {
  padding: 10px 15px;
  margin: 10px 3px;
  position: relative;
}
.navbar .navbar-nav > li > a.btn {
  margin: 15px 3px;
  padding: 8px 16px;
}
.navbar .navbar-nav > li > a.btn-round {
  margin: 16px 3px;
}
.navbar .navbar-nav > li > a [class^="fa"] {
  font-size: 19px;
  position: relative;
  line-height: 16px;
  top: 1px;
}
.navbar .navbar-nav .notification {
  position: absolute;
  background-color: #FB404B;
  text-align: center;
  border-radius: 10px;
  min-width: 18px;
  padding: 0 5px;
  height: 18px;
  font-size: 12px;
  color: #FFFFFF;
  font-weight: bold;
  line-height: 18px;
  top: 0px;
  left: 7px;
}
.navbar .btn {
  margin: 15px 3px;
  font-size: 14px;
}
.navbar .btn-simple {
  font-size: 16px;
}
.navbar.fixed {
  width: calc(100% - $sidebar-width);
  right: 0;
  left: auto;
  border-radius: 0;
}

.navbar-nav > li > .dropdown-menu {
  border-radius: 3px;
  margin-top: -5px;
}

.navbar-transparent .navbar-brand, [class*="navbar-ct"] .navbar-brand {
  color: #FFFFFF;
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.navbar-transparent .navbar-brand:focus, .navbar-transparent .navbar-brand:hover, [class*="navbar-ct"] .navbar-brand:focus, [class*="navbar-ct"] .navbar-brand:hover {
  background-color: transparent;
  opacity: 1;
  filter: alpha(opacity=100);
}
.navbar-transparent .navbar-nav > li > a:not(.btn), [class*="navbar-ct"] .navbar-nav > li > a:not(.btn) {
  color: #777;
  border-color: #777;
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.navbar-transparent .navbar-nav > .active > a:not(.btn),
.navbar-transparent .navbar-nav > .active > a:hover:not(.btn),
.navbar-transparent .navbar-nav > .active > a:focus:not(.btn),
.navbar-transparent .navbar-nav > li > a:hover:not(.btn),
.navbar-transparent .navbar-nav > li > a:focus:not(.btn), [class*="navbar-ct"] .navbar-nav > .active > a:not(.btn),
[class*="navbar-ct"] .navbar-nav > .active > a:hover:not(.btn),
[class*="navbar-ct"] .navbar-nav > .active > a:focus:not(.btn),
[class*="navbar-ct"] .navbar-nav > li > a:hover:not(.btn),
[class*="navbar-ct"] .navbar-nav > li > a:focus:not(.btn) {
  background-color: transparent;
  border-radius: 3px;
  color: #777;
  opacity: 1;
  filter: alpha(opacity=100);
}
.navbar-transparent .navbar-nav .nav > li > a.btn:hover, [class*="navbar-ct"] .navbar-nav .nav > li > a.btn:hover {
  background-color: transparent;
}
.navbar-transparent .navbar-nav > .dropdown > a .caret,
.navbar-transparent .navbar-nav > .dropdown > a:hover .caret,
.navbar-transparent .navbar-nav > .dropdown > a:focus .caret, [class*="navbar-ct"] .navbar-nav > .dropdown > a .caret,
[class*="navbar-ct"] .navbar-nav > .dropdown > a:hover .caret,
[class*="navbar-ct"] .navbar-nav > .dropdown > a:focus .caret {
  border-bottom-color: #FFFFFF;
  border-top-color: #FFFFFF;
}
.navbar-transparent .navbar-nav > .open > a,
.navbar-transparent .navbar-nav > .open > a:hover,
.navbar-transparent .navbar-nav > .open > a:focus, [class*="navbar-ct"] .navbar-nav > .open > a,
[class*="navbar-ct"] .navbar-nav > .open > a:hover,
[class*="navbar-ct"] .navbar-nav > .open > a:focus {
  background-color: transparent;
  color: #777;
  opacity: 1;
  filter: alpha(opacity=100);
}
.navbar-transparent .btn-default, [class*="navbar-ct"] .btn-default {
  color: #FFFFFF;
  border-color: #FFFFFF;
}
.navbar-transparent .btn-default.btn-fill, [class*="navbar-ct"] .btn-default.btn-fill {
  color: #9A9A9A;
  background-color: #FFFFFF;
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.navbar-transparent .btn-default.btn-fill:hover,
.navbar-transparent .btn-default.btn-fill:focus,
.navbar-transparent .btn-default.btn-fill:active,
.navbar-transparent .btn-default.btn-fill.active,
.navbar-transparent .open .dropdown-toggle.btn-fill.btn-default, [class*="navbar-ct"] .btn-default.btn-fill:hover,
[class*="navbar-ct"] .btn-default.btn-fill:focus,
[class*="navbar-ct"] .btn-default.btn-fill:active,
[class*="navbar-ct"] .btn-default.btn-fill.active,
[class*="navbar-ct"] .open .dropdown-toggle.btn-fill.btn-default {
  border-color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}

.navbar-transparent .dropdown-menu .divider {
  background-color: rgba(255, 255, 255, 0.2);
}

.nav-open .nav .caret {
  border-bottom-color: #FFFFFF;
  border-top-color: #FFFFFF;
}

.navbar-default {
  background: #FFF!important;
}
.navbar-default .navbar-nav > li > a:not(.btn) {
  color: #777;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:not(.btn):hover,
.navbar-default .navbar-nav > .active > a:not(.btn):focus,
.navbar-default .navbar-nav > li > a:not(.btn):hover,
.navbar-default .navbar-nav > li > a:not(.btn):focus {
  background-color: transparent;
  border-radius: 1px;
  color: #777;
  opacity: 1;
  filter: alpha(opacity=100);
}
.navbar-default .navbar-nav > .dropdown > a:hover .caret,
.navbar-default .navbar-nav > .dropdown > a:focus .caret {
  border-bottom-color: #777;
  border-top-color: #777;
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
  background-color: transparent;
  color: #FFF;
}
.navbar-default .navbar-nav .navbar-toggle:hover, .navbar-default .navbar-nav .navbar-toggle:focus {
  background-color: transparent;
}
.navbar-default:not(.navbar-transparent) .btn-default:hover {
  color: #005F34;
  border-color: #005F34;
}
.navbar-default:not(.navbar-transparent) .btn-neutral, .navbar-default:not(.navbar-transparent) .btn-neutral:hover, .navbar-default:not(.navbar-transparent) .btn-neutral:active {
  color: #9A9A9A;
}

/*      Navbar with icons            */
.navbar-icons.navbar .navbar-brand {
  margin-top: 12px;
  margin-bottom: 12px;
}
.navbar-icons .navbar-nav > li > a {
  text-align: center;
  padding: 6px 15px;
  margin: 6px 3px;
}
.navbar-icons .navbar-nav [class^="pe"] {
  font-size: 30px;
  position: relative;
}
.navbar-icons .navbar-nav p {
  margin: 3px 0 0;
}

.navbar-form {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.navbar-form .form-control {
  border-radius: 0;
  border: 0;
  padding: 0;
  background-color: transparent;
  height: 22px;
  font-size: 16px;
  line-height: 1.5;
  color: #E3E3E3;
}
.navbar-transparent .navbar-form .form-control, [class*="navbar-ct"] .navbar-form .form-control {
  color: #FFFFFF;
  border: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
}

.navbar-ct-blue {
  background-color: #4091e2;
}

.navbar-ct-azure {
  background-color: #63d8f1;
}

.navbar-ct-green {
  background-color: #a1e82c;
}

.navbar-ct-orange {
  background-color: #ffbc67;
}

.navbar-ct-red {
  background-color: #fc727a;
}

.navbar-transparent {
  padding-top: 15px;
  background-color: transparent;
  border-bottom: 1px solid transparent;
}

.navbar-toggle {
  margin-top: 19px;
  margin-bottom: 19px;
  border: 0;
}
.navbar-toggle .icon-bar {
  background-color: #FFFFFF;
}
.navbar-toggle .navbar-collapse,
.navbar-toggle .navbar-form {
  border-color: transparent;
}
.navbar-toggle.navbar-default .navbar-toggle:hover, .navbar-toggle.navbar-default .navbar-toggle:focus {
  background-color: transparent;
}

.footer {
  background-color: #FFFFFF;
  line-height: 20px;
}
.footer nav > ul {
  list-style: none;
  margin: 0;
  padding: 0;
  font-weight: normal;
}
.footer nav > ul a:not(.btn) {
  color: #9A9A9A;
  display: block;
  margin-bottom: 3px;
}
.footer nav > ul a:not(.btn):hover, .footer nav > ul a:not(.btn):focus {
  color: #777777;
}
.footer .social-area {
  padding: 15px 0;
}
.footer .social-area h5 {
  padding-bottom: 15px;
}
.footer .social-area > a:not(.btn) {
  color: #9A9A9A;
  display: inline-block;
  vertical-align: top;
  padding: 10px 5px;
  font-size: 20px;
  font-weight: normal;
  line-height: 20px;
  text-align: center;
}
.footer .social-area > a:not(.btn):hover, .footer .social-area > a:not(.btn):focus {
  color: #777777;
}
.footer .copyright {
  color: #777777;
  padding: 10px 15px;
  margin: 10px 3px;
  line-height: 20px;
  font-size: 14px;
}
.footer hr {
  border-color: #DDDDDD;
}
.footer .title {
  color: #777777;
}

.footer-default {
  background-color: #F5F5F5;
}

.footer:not(.footer-big) nav > ul {
  font-size: 14px;
}
.footer:not(.footer-big) nav > ul li {
  margin-left: 20px;
  float: left;
}
.footer:not(.footer-big) nav > ul a {
  padding: 10px 0px;
  margin: 10px 10px 10px 0px;
}

.btn-group.select {
  overflow: hidden;
}

.btn-group.select.open {
  overflow: visible;
}

.card {
  border-left: 1px solid #ddd!important;
  border-right: 1px solid #ddd!important;
  border-bottom: 1px solid #ddd!important;
  background-color: #FFFFFF;
  margin-bottom: 30px;
  padding: 20px;

}
.card .image {
  width: 100%;
  overflow: hidden;
  height: 280px;
  border-radius: 4px 4px 0 0;
  position: relative;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.card .image img {
  width: 100%;
}
.card .filter {
  position: absolute;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.68);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  opacity: 0;
  filter: alpha(opacity=0);
}
.card .filter .btn {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.card:hover .filter {
  opacity: 1;
  filter: alpha(opacity=100);
}
.card .btn-hover {
  opacity: 0;
  filter: alpha(opacity=0);
}
.card:hover .btn-hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.card .content {
  padding: 15px 15px 10px 15px;
}
.card .header {
  padding: 15px 15px 0;
}
.card .category,
.card label {
  font-size: 16px;
  font-weight: 400;
  color: #000;
  margin-bottom: 0px;
}
.card .category i,
.card label i {
  font-size: 16px;
}
.card label {
  font-size: 16px;
  margin-bottom: 5px;
}
.card .title {
  margin: 0;
  color: #333333;
  font-weight: 300;
}
.card .avatar {
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 5px;
}
.card .description {
  font-size: 14px;
  color: #333;
}
.card .footer {
  padding: 0;
  background-color: transparent;
  line-height: 30px;
}
.card .footer .legend {
  padding: 5px 0;
}
.card .footer hr {
  margin-top: 5px;
  margin-bottom: 5px;
}
.card .stats {
  color: #a9a9a9;
}
.card .footer div {
  display: inline-block;
}
.card .author {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}
.card .author i {
  font-size: 14px;
}
.card h6 {
  font-size: 12px;
  margin: 0;
}
.card.card-separator:after {
  height: 100%;
  right: -15px;
  top: 0;
  width: 1px;
  background-color: #DDDDDD;
  content: "";
  position: absolute;
}
.card .ct-chart {
  margin: 30px 0 30px;
  height: 245px;
}
.card .table tbody td:first-child,
.card .table thead th:first-child {
  padding-left: 15px;
}
.card .table tbody td:last-child,
.card .table thead th:last-child {
  padding-right: 15px;
}
.alert {
  border-radius: 4px;
  position: relative;
}
.alert.alert-with-icon {
  padding-left: 65px;
}

.card-user .image {
  height: 110px;
}
.card-user .image-plain {
  height: 0;
  margin-top: 110px;
}
.card-user .author {
  text-align: center;
  text-transform: none;
  margin-top: -70px;
}
.card-user .avatar {
  width: 124px;
  height: 124px;
  border: 5px solid #FFFFFF;
  position: relative;
  margin-bottom: 15px;
}
.card-user .avatar.border-gray {
  border-color: #EEEEEE;
}
.card-user .title {
  line-height: 24px;
}
.card-user .content {
  min-height: 240px;
}

.card-user .footer,
.card-price .footer {
  padding: 5px 15px 10px;
}
.card-user hr,
.card-price hr {
  margin: 5px 15px;
}

.card-plain {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;
}
.card-plain .image {
  border-radius: 4px;
}

.ct-label {
  fill: rgba(0, 0, 0, 0.4);
  color: rgba(0, 0, 0, 0.4);
  font-size: 1.3rem;
  line-height: 1;
}

.ct-chart-line .ct-label,
.ct-chart-bar .ct-label {
  display: block;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.ct-label.ct-horizontal.ct-start {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-label.ct-vertical.ct-start {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: flex-end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: flex-end;
  justify-content: flex-end;
  text-align: right;
  text-anchor: end;
}

.ct-label.ct-vertical.ct-end {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-chart-bar .ct-label.ct-horizontal.ct-start {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  text-anchor: start;
}

.ct-chart-bar .ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  text-anchor: start;
}

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-start {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-start {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: flex-end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: flex-end;
  justify-content: flex-end;
  text-align: right;
  text-anchor: end;
}

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-end {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: end;
}

.ct-grid {
  stroke: rgba(0, 0, 0, 0.2);
  stroke-width: 1px;
  stroke-dasharray: 2px;
}

.ct-point {
  stroke-width: 8px;
  stroke-linecap: round;
}

.ct-line {
  fill: none;
  stroke-width: 3px;
}

.ct-area {
  stroke: none;
  fill-opacity: 0.8;
}

.ct-bar {
  fill: none;
  stroke-width: 10px;
}

.ct-slice-donut {
  fill: none;
  stroke-width: 60px;
}

.ct-series-a .ct-point, .ct-series-a .ct-line, .ct-series-a .ct-bar, .ct-series-a .ct-slice-donut {
  stroke: #1DC7EA;
}
.ct-series-a .ct-slice-pie, .ct-series-a .ct-area {
  fill: #1DC7EA;
}

.ct-series-b .ct-point, .ct-series-b .ct-line, .ct-series-b .ct-bar, .ct-series-b .ct-slice-donut {
  stroke: #FB404B;
}
.ct-series-b .ct-slice-pie, .ct-series-b .ct-area {
  fill: #FB404B;
}

.ct-series-c .ct-point, .ct-series-c .ct-line, .ct-series-c .ct-bar, .ct-series-c .ct-slice-donut {
  stroke: #FFA534;
}
.ct-series-c .ct-slice-pie, .ct-series-c .ct-area {
  fill: #FFA534;
}

.ct-series-d .ct-point, .ct-series-d .ct-line, .ct-series-d .ct-bar, .ct-series-d .ct-slice-donut {
  stroke: #9368E9;
}
.ct-series-d .ct-slice-pie, .ct-series-d .ct-area {
  fill: #9368E9;
}

.ct-series-e .ct-point, .ct-series-e .ct-line, .ct-series-e .ct-bar, .ct-series-e .ct-slice-donut {
  stroke: #87CB16;
}
.ct-series-e .ct-slice-pie, .ct-series-e .ct-area {
  fill: #87CB16;
}

.ct-series-f .ct-point, .ct-series-f .ct-line, .ct-series-f .ct-bar, .ct-series-f .ct-slice-donut {
  stroke: #1F77D0;
}
.ct-series-f .ct-slice-pie, .ct-series-f .ct-area {
  fill: #1F77D0;
}

.ct-series-g .ct-point, .ct-series-g .ct-line, .ct-series-g .ct-bar, .ct-series-g .ct-slice-donut {
  stroke: #5e5e5e;
}
.ct-series-g .ct-slice-pie, .ct-series-g .ct-area {
  fill: #5e5e5e;
}

.ct-series-h .ct-point, .ct-series-h .ct-line, .ct-series-h .ct-bar, .ct-series-h .ct-slice-donut {
  stroke: #dd4b39;
}
.ct-series-h .ct-slice-pie, .ct-series-h .ct-area {
  fill: #dd4b39;
}

.ct-series-i .ct-point, .ct-series-i .ct-line, .ct-series-i .ct-bar, .ct-series-i .ct-slice-donut {
  stroke: #35465c;
}
.ct-series-i .ct-slice-pie, .ct-series-i .ct-area {
  fill: #35465c;
}

.ct-series-j .ct-point, .ct-series-j .ct-line, .ct-series-j .ct-bar, .ct-series-j .ct-slice-donut {
  stroke: #e52d27;
}
.ct-series-j .ct-slice-pie, .ct-series-j .ct-area {
  fill: #e52d27;
}

.ct-series-k .ct-point, .ct-series-k .ct-line, .ct-series-k .ct-bar, .ct-series-k .ct-slice-donut {
  stroke: #55acee;
}
.ct-series-k .ct-slice-pie, .ct-series-k .ct-area {
  fill: #55acee;
}

.ct-series-l .ct-point, .ct-series-l .ct-line, .ct-series-l .ct-bar, .ct-series-l .ct-slice-donut {
  stroke: #cc2127;
}
.ct-series-l .ct-slice-pie, .ct-series-l .ct-area {
  fill: #cc2127;
}

.ct-series-m .ct-point, .ct-series-m .ct-line, .ct-series-m .ct-bar, .ct-series-m .ct-slice-donut {
  stroke: #1769ff;
}
.ct-series-m .ct-slice-pie, .ct-series-m .ct-area {
  fill: #1769ff;
}

.ct-series-n .ct-point, .ct-series-n .ct-line, .ct-series-n .ct-bar, .ct-series-n .ct-slice-donut {
  stroke: #6188e2;
}
.ct-series-n .ct-slice-pie, .ct-series-n .ct-area {
  fill: #6188e2;
}

.ct-series-o .ct-point, .ct-series-o .ct-line, .ct-series-o .ct-bar, .ct-series-o .ct-slice-donut {
  stroke: #a748ca;
}
.ct-series-o .ct-slice-pie, .ct-series-o .ct-area {
  fill: #a748ca;
}

.ct-square {
  display: block;
  position: relative;
  width: 100%;
}
.ct-square:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 100%;
}
.ct-square:after {
  content: "";
  display: table;
  clear: both;
}
.ct-square > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-minor-second {
  display: block;
  position: relative;
  width: 100%;
}
.ct-minor-second:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 93.75%;
}
.ct-minor-second:after {
  content: "";
  display: table;
  clear: both;
}
.ct-minor-second > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-second {
  display: block;
  position: relative;
  width: 100%;
}
.ct-major-second:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 88.88889%;
}
.ct-major-second:after {
  content: "";
  display: table;
  clear: both;
}
.ct-major-second > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-minor-third {
  display: block;
  position: relative;
  width: 100%;
}
.ct-minor-third:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 83.33333%;
}
.ct-minor-third:after {
  content: "";
  display: table;
  clear: both;
}
.ct-minor-third > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-third {
  display: block;
  position: relative;
  width: 100%;
}
.ct-major-third:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 80%;
}
.ct-major-third:after {
  content: "";
  display: table;
  clear: both;
}
.ct-major-third > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-perfect-fourth {
  display: block;
  position: relative;
  width: 100%;
}
.ct-perfect-fourth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 75%;
}
.ct-perfect-fourth:after {
  content: "";
  display: table;
  clear: both;
}
.ct-perfect-fourth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-perfect-fifth {
  display: block;
  position: relative;
  width: 100%;
}
.ct-perfect-fifth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 66.66667%;
}
.ct-perfect-fifth:after {
  content: "";
  display: table;
  clear: both;
}
.ct-perfect-fifth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-minor-sixth {
  display: block;
  position: relative;
  width: 100%;
}
.ct-minor-sixth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 62.5%;
}
.ct-minor-sixth:after {
  content: "";
  display: table;
  clear: both;
}
.ct-minor-sixth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-golden-section {
  display: block;
  position: relative;
  width: 100%;
}
.ct-golden-section:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 61.8047%;
}
.ct-golden-section:after {
  content: "";
  display: table;
  clear: both;
}
.ct-golden-section > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-sixth {
  display: block;
  position: relative;
  width: 100%;
}
.ct-major-sixth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 60%;
}
.ct-major-sixth:after {
  content: "";
  display: table;
  clear: both;
}
.ct-major-sixth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-minor-seventh {
  display: block;
  position: relative;
  width: 100%;
}
.ct-minor-seventh:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 56.25%;
}
.ct-minor-seventh:after {
  content: "";
  display: table;
  clear: both;
}
.ct-minor-seventh > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-seventh {
  display: block;
  position: relative;
  width: 100%;
}
.ct-major-seventh:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 53.33333%;
}
.ct-major-seventh:after {
  content: "";
  display: table;
  clear: both;
}
.ct-major-seventh > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-octave {
  display: block;
  position: relative;
  width: 100%;
}
.ct-octave:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 50%;
}
.ct-octave:after {
  content: "";
  display: table;
  clear: both;
}
.ct-octave > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-tenth {
  display: block;
  position: relative;
  width: 100%;
}
.ct-major-tenth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 40%;
}
.ct-major-tenth:after {
  content: "";
  display: table;
  clear: both;
}
.ct-major-tenth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-eleventh {
  display: block;
  position: relative;
  width: 100%;
}
.ct-major-eleventh:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 37.5%;
}
.ct-major-eleventh:after {
  content: "";
  display: table;
  clear: both;
}
.ct-major-eleventh > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-twelfth {
  display: block;
  position: relative;
  width: 100%;
}
.ct-major-twelfth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 33.33333%;
}
.ct-major-twelfth:after {
  content: "";
  display: table;
  clear: both;
}
.ct-major-twelfth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-double-octave {
  display: block;
  position: relative;
  width: 100%;
}
.ct-double-octave:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 25%;
}
.ct-double-octave:after {
  content: "";
  display: table;
  clear: both;
}
.ct-double-octave > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

@media (min-width: 992px) {
  .navbar-form {
    margin-top: 21px;
    margin-bottom: 21px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .footer:not(.footer-big) nav > ul li:first-child {
    margin-left: 0;
  }

  body > .navbar-collapse.collapse {
    display: none !important;
  }

  .card form [class*="col-"] {
    padding: 6px;
  }
  .card form [class*="col-"]:first-child {
    padding-left: 15px;
  }
  .card form [class*="col-"]:last-child {
    padding-right: 15px;
  }
}
/*          Changes for small display      */
@media (max-width: 991px) {
  .sidebar-fechado {
    z-index: 1000;
    left: -280px;
  }
  .sidebar-aberto {
    z-index: 1000;
    left: 0;
  }

  .main-panel {
    width: 100%;
  }

  .navbar-transparent {
    padding-top: 15px;
    background-color: rgba(0, 0, 0, 0.45);
  }

  body {
    position: relative;
  }

  .wrapper {
    -webkit-transform: translate3d(0px, 0, 0);
    -moz-transform: translate3d(0px, 0, 0);
    -o-transform: translate3d(0px, 0, 0);
    -ms-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
    -webkit-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    left: 0;
    background-color: white;
  }

  .navbar .container {
    left: 0;
    width: 100%;
    -webkit-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    position: relative;
  }

  .navbar .navbar-collapse.collapse,
  .navbar .navbar-collapse.collapse.in,
  .navbar .navbar-collapse.collapsing {
    display: none !important;
  }

  .navbar-nav > li {
    float: none;
    position: relative;
    display: block;
  }

  body > .navbar-collapse {
    position: fixed;
    display: block;
    top: 0;
    height: 100%;
    width: 250px;
    right: 0;
    z-index: 1032;
    visibility: visible;
    background-color: #999;
    overflow-y: visible;
    border-top: none;
    text-align: left;
    padding: 0;
    -webkit-transform: translate3d(250px, 0, 0);
    -moz-transform: translate3d(250px, 0, 0);
    -o-transform: translate3d(250px, 0, 0);
    -ms-transform: translate3d(250px, 0, 0);
    transform: translate3d(250px, 0, 0);
    -webkit-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
  body > .navbar-collapse > ul {
    position: relative;
    z-index: 4;
    overflow-y: scroll;
    height: calc(100vh - 61px);
    width: 100%;
  }
  body > .navbar-collapse .nav > li > a {
    padding: 30px 25px;
  }
  body > .navbar-collapse::before {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: #282828;
    display: block;
    content: "";
    z-index: 1;
  }
  body > .navbar-collapse .logo {
    position: relative;
    z-index: 4;
  }

  .nav-open .navbar-collapse {
    -webkit-transform: translate3d(0px, 0, 0);
    -moz-transform: translate3d(0px, 0, 0);
    -o-transform: translate3d(0px, 0, 0);
    -ms-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
  }

  .nav-open .navbar .container {
    left: -250px;
  }

  .nav-open .wrapper {
    left: 0;
    -webkit-transform: translate3d(-250px, 0, 0);
    -moz-transform: translate3d(-250px, 0, 0);
    -o-transform: translate3d(-250px, 0, 0);
    -ms-transform: translate3d(-250px, 0, 0);
    transform: translate3d(-250px, 0, 0);
  }

  .navbar-toggle .icon-bar {
    display: block;
    position: relative;
    background: #fff;
    width: 24px;
    height: 2px;
    border-radius: 1px;
    margin: 0 auto;
  }

  .navbar-header .navbar-toggle {
    margin: 10px 15px 10px 0;
    width: 40px;
    height: 40px;
  }

  .bar1,
  .bar2,
  .bar3 {
    outline: 1px solid transparent;
  }

  .bar1 {
    top: 0px;
    -webkit-animation: topbar-back 500ms linear 0s;
    -moz-animation: topbar-back 500ms linear 0s;
    animation: topbar-back 500ms 0s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .bar2 {
    opacity: 1;
  }

  .bar3 {
    bottom: 0px;
    -webkit-animation: bottombar-back 500ms linear 0s;
    -moz-animation: bottombar-back 500ms linear 0s;
    animation: bottombar-back 500ms 0s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .toggled .bar1 {
    top: 6px;
    -webkit-animation: topbar-x 500ms linear 0s;
    -moz-animation: topbar-x 500ms linear 0s;
    animation: topbar-x 500ms 0s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .toggled .bar2 {
    opacity: 0;
  }

  .toggled .bar3 {
    bottom: 6px;
    -webkit-animation: bottombar-x 500ms linear 0s;
    -moz-animation: bottombar-x 500ms linear 0s;
    animation: bottombar-x 500ms 0s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  @keyframes topbar-x {
    0% {
      top: 0px;
      transform: rotate(0deg);
    }
    45% {
      top: 6px;
      transform: rotate(145deg);
    }
    75% {
      transform: rotate(130deg);
    }
    100% {
      transform: rotate(135deg);
    }
  }
  @-webkit-keyframes topbar-x {
    0% {
      top: 0px;
      -webkit-transform: rotate(0deg);
    }
    45% {
      top: 6px;
      -webkit-transform: rotate(145deg);
    }
    75% {
      -webkit-transform: rotate(130deg);
    }
    100% {
      -webkit-transform: rotate(135deg);
    }
  }
  @-moz-keyframes topbar-x {
    0% {
      top: 0px;
      -moz-transform: rotate(0deg);
    }
    45% {
      top: 6px;
      -moz-transform: rotate(145deg);
    }
    75% {
      -moz-transform: rotate(130deg);
    }
    100% {
      -moz-transform: rotate(135deg);
    }
  }
  @keyframes topbar-back {
    0% {
      top: 6px;
      transform: rotate(135deg);
    }
    45% {
      transform: rotate(-10deg);
    }
    75% {
      transform: rotate(5deg);
    }
    100% {
      top: 0px;
      transform: rotate(0);
    }
  }
  @-webkit-keyframes topbar-back {
    0% {
      top: 6px;
      -webkit-transform: rotate(135deg);
    }
    45% {
      -webkit-transform: rotate(-10deg);
    }
    75% {
      -webkit-transform: rotate(5deg);
    }
    100% {
      top: 0px;
      -webkit-transform: rotate(0);
    }
  }
  @-moz-keyframes topbar-back {
    0% {
      top: 6px;
      -moz-transform: rotate(135deg);
    }
    45% {
      -moz-transform: rotate(-10deg);
    }
    75% {
      -moz-transform: rotate(5deg);
    }
    100% {
      top: 0px;
      -moz-transform: rotate(0);
    }
  }
  @keyframes bottombar-x {
    0% {
      bottom: 0px;
      transform: rotate(0deg);
    }
    45% {
      bottom: 6px;
      transform: rotate(-145deg);
    }
    75% {
      transform: rotate(-130deg);
    }
    100% {
      transform: rotate(-135deg);
    }
  }
  @-webkit-keyframes bottombar-x {
    0% {
      bottom: 0px;
      -webkit-transform: rotate(0deg);
    }
    45% {
      bottom: 6px;
      -webkit-transform: rotate(-145deg);
    }
    75% {
      -webkit-transform: rotate(-130deg);
    }
    100% {
      -webkit-transform: rotate(-135deg);
    }
  }
  @-moz-keyframes bottombar-x {
    0% {
      bottom: 0px;
      -moz-transform: rotate(0deg);
    }
    45% {
      bottom: 6px;
      -moz-transform: rotate(-145deg);
    }
    75% {
      -moz-transform: rotate(-130deg);
    }
    100% {
      -moz-transform: rotate(-135deg);
    }
  }
  @keyframes bottombar-back {
    0% {
      bottom: 6px;
      transform: rotate(-135deg);
    }
    45% {
      transform: rotate(10deg);
    }
    75% {
      transform: rotate(-5deg);
    }
    100% {
      bottom: 0px;
      transform: rotate(0);
    }
  }
  @-webkit-keyframes bottombar-back {
    0% {
      bottom: 6px;
      -webkit-transform: rotate(-135deg);
    }
    45% {
      -webkit-transform: rotate(10deg);
    }
    75% {
      -webkit-transform: rotate(-5deg);
    }
    100% {
      bottom: 0px;
      -webkit-transform: rotate(0);
    }
  }
  @-moz-keyframes bottombar-back {
    0% {
      bottom: 6px;
      -moz-transform: rotate(-135deg);
    }
    45% {
      -moz-transform: rotate(10deg);
    }
    75% {
      -moz-transform: rotate(-5deg);
    }
    100% {
      bottom: 0px;
      -moz-transform: rotate(0);
    }
  }
  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }


  .navbar-nav {
    margin: 1px 0;
  }

  [class*="navbar-"] .navbar-nav > li > a,
  [class*="navbar-"] .navbar-nav > li > a:hover,
  [class*="navbar-"] .navbar-nav > li > a:focus,
  [class*="navbar-"] .navbar-nav .active > a,
  [class*="navbar-"] .navbar-nav .active > a:hover,
  [class*="navbar-"] .navbar-nav .active > a:focus,
  [class*="navbar-"] .navbar-nav .open .dropdown-menu > li > a,
  [class*="navbar-"] .navbar-nav .open .dropdown-menu > li > a:hover,
  [class*="navbar-"] .navbar-nav .open .dropdown-menu > li > a:focus,
  [class*="navbar-"] .navbar-nav .navbar-nav .open .dropdown-menu > li > a:active {
    color: white;
  }
  [class*="navbar-"] .navbar-nav > li > a,
  [class*="navbar-"] .navbar-nav > li > a:hover,
  [class*="navbar-"] .navbar-nav > li > a:focus,
  [class*="navbar-"] .navbar-nav .open .dropdown-menu > li > a,
  [class*="navbar-"] .navbar-nav .open .dropdown-menu > li > a:hover,
  [class*="navbar-"] .navbar-nav .open .dropdown-menu > li > a:focus {
    opacity: .7;
    background: transparent;
  }
  [class*="navbar-"] .navbar-nav.navbar-nav .open .dropdown-menu > li > a:active {
    opacity: 1;
  }
  [class*="navbar-"] .navbar-nav .dropdown > a:hover .caret {
    border-bottom-color: #777;
    border-top-color: #777;
  }
  [class*="navbar-"] .navbar-nav .dropdown > a:active .caret {
    border-bottom-color: white;
    border-top-color: white;
  }

  .dropdown-menu {
    display: none;
  }


  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 0;
    top: 0;
    left: auto;
    right: 250px;
    content: "";
    z-index: 9999;
    overflow-x: hidden;
  }

  .social-line .btn {
    margin: 0 0 10px 0;
  }

  .subscribe-line .form-control {
    margin: 0 0 10px 0;
  }

  .social-line.pull-right {
    float: none;
  }

  .footer nav.pull-left {
    float: none !important;
  }

  .footer:not(.footer-big) nav > ul li {
    float: none;
  }

  .social-area.pull-right {
    float: none !important;
  }

  .form-control + .form-control-feedback {
    margin-top: -8px;
  }

  .navbar-toggle:hover, .navbar-toggle:focus {
    background-color: transparent !important;
  }

  .btn.dropdown-toggle {
    margin-bottom: 0;
  }

  .media-post .author {
    width: 20%;
    float: none !important;
    display: block;
    margin: 0 auto 10px;
  }

  .media-post .media-body {
    width: 100%;
  }

  .navbar-collapse.collapse {
    height: 100% !important;
  }

  .navbar-collapse.collapse.in {
    display: block;
  }

  .navbar-header .collapse, .navbar-toggle {
    display: block !important;
  }

  .navbar-header {
    float: none;
  }

  .navbar-collapse .nav p {
    font-size: 14px;
    margin: 0;
  }
  .navbar-collapse [class^="pe-7s-"] {
    float: left;
    font-size: 20px;
    margin-right: 10px;
  }
}
@media (min-width: 1200px) {
  .table-full-width {
    margin-left: -15px;
    margin-right: -15px;
  }

  .table-responsive {
    overflow: visible;
  }
}
@media (max-width: 1200px) {
  .table-responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
  }
}

@media screen and (max-width: 768px) {
  .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 16px;
  text-align: left;
  background-color: #FFF;
  border: 1px solid #cccccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  -webkit-background-clip: padding-box;
          background-clip: padding-box;
}
}

.dropdown-menu > li > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333!important;
    white-space: nowrap;
}

.dropdown-menu > li.active > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #fff!important;
    white-space: nowrap;
}

.navbar-default .navbar-nav > li.dropdown > a.dropdown-toggle {
    color: #FFF!important;
    &:hover, &:focus {
      color: #FFF!important;
    }
    .caret {
      color: #FFF;
      &:hover, &:focus {
        color: #FFF!important;
      }
    }
}

.navbar-default .navbar-nav > .dropdown > a:hover .caret, .navbar-default .navbar-nav > .dropdown > a:focus .caret {
    border-bottom-color: #FFF!important;
    border-top-color: #FFF!important;
}

.navbar-brand {
  color: #FFF!important;
  font-weight: bold!important;
  margin-left: 140px!important;
}

#checkbox-animated {
  $checked-color: #3AE865;
  $unchecked-color: #E3E3E3;
  $checkbox-height: 20px;
  $duration: .4s;
      
  /* Checkmark style starts */

  @-moz-keyframes dothabottomcheck{
      0% { height: 0; }
      100% { height: $checkbox-height/2; }
  }

  @-webkit-keyframes dothabottomcheck{
      0% { height: 0; }
      100% { height: $checkbox-height/2; }
  }

  @keyframes dothabottomcheck{
      0% { height: 0; }
      100% { height: $checkbox-height/2;  }
  }

  @keyframes dothatopcheck{
      0% { height: 0; }
      50% { height: 0; }
      100% { height: $checkbox-height * 1.2; }
  }
  @-webkit-keyframes dothatopcheck{
      0% { height: 0; }
      50% { height: 0; }
      100% { height: $checkbox-height * 1.2; }
  }
  @-moz-keyframes dothatopcheck{
      0% { height: 0; }
      50% { height: 0; }
      100% { height: $checkbox-height * 1.2; }
  }

  input[type=checkbox]{
      display:none;
  }

  .check-box {
      height: $checkbox-height;
      width: $checkbox-height;
      background-color: transparent;
      border: $checkbox-height * .05 solid $unchecked-color;
      border-radius: 3px;
      position: relative;
      display: inline-block;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      -moz-transition: border-color ease $duration/2;
      -o-transition: border-color ease $duration/2;
      -webkit-transition: border-color ease $duration/2;
      transition: border-color ease $duration/2;
      cursor:pointer;

      &::before, &::after {
          -moz-box-sizing: border-box;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          position: absolute;
          height: 0;
          width: $checkbox-height * .1;
          background-color: $checked-color;
          display: inline-block;
          -moz-transform-origin: left top;
          -ms-transform-origin: left top;
          -o-transform-origin: left top;
          -webkit-transform-origin: left top;
          transform-origin: left top;
          border-radius: 5px;
          content: ' ';
          -webkit-transition: opacity ease .5;
          -moz-transition: opacity ease .5;
          transition: opacity ease .5;
      }

      &::before {
          top:$checkbox-height * .72;
          left: $checkbox-height * .41;
          box-shadow: 0 0 0 $checkbox-height * .05 #FFF;
          -moz-transform: rotate(-135deg);
          -ms-transform: rotate(-135deg);
          -o-transform: rotate(-135deg);
          -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg);
      }

      &::after {
          top: $checkbox-height * .37;
          left: $checkbox-height * .05;
          -moz-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
      }
  }

  input[type=checkbox]:checked + .check-box,
  .check-box.checked{
      border-color:$checked-color;
      
      &::after{
          height: $checkbox-height * .5;
          -moz-animation: dothabottomcheck $duration/2 ease 0s forwards;
          -o-animation: dothabottomcheck $duration/2 ease 0s forwards;
          -webkit-animation: dothabottomcheck $duration/2 ease 0s forwards;
          animation: dothabottomcheck $duration/2 ease 0s forwards;
      }
       
      &::before{
          height: $checkbox-height * 1.2;
          -moz-animation: dothatopcheck $duration ease 0s forwards;
          -o-animation: dothatopcheck $duration ease 0s forwards;
          -webkit-animation: dothatopcheck $duration ease 0s forwards;
          animation: dothatopcheck $duration ease 0s forwards;
      }
  }
}
